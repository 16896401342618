import "../styles/Avenir.scss"
import appli from "../assets/app.webp"
import appli2 from "../assets/google-play.png"

function Avenir(){

    return (
        <section className="avenir">
            <a href="https://apps.apple.com/fr/app/11sur10/id6739251747" target="_blank" rel="noopener noreferrer"><img src={appli}  /></a>
            <a href="https://play.google.com/store/apps/details?id=com.onzesur10.app&hl=fr" target="_blank" rel="noopener noreferrer"><img src={appli2}  /></a>

        </section>
    )
}

export default Avenir